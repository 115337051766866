import { BalHeading, BalText } from "@baloise/ds-react";
import { Form } from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { beforeWeStartFormConfig, DealerLayout } from "@modules/dealer";
import { Footer, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const DealerBeforeWeStartPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const { nextPage } = getNavigationPages(allPages, page);
    const { t } = useTranslation();

    const handleSubmit = (values: AppState): void => {
        setAppData(values);

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: AppState): void => {
        setAppData(values);
    };

    return (
        <>
            <DealerLayout
                title={t("dealer.beforewestart.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <main className="container is-compact mt-large flex flex-direction-column">
                    <div className="flex-1">
                        <div className="intro">
                            <BalHeading level="h1">
                                {t("dealer.beforewestart.title")}
                            </BalHeading>
                            <BalText>{t("dealer.beforewestart.intro")}</BalText>
                        </div>

                        <Form
                            formClassName="mb-medium"
                            scrollToFieldError
                            errorMessageCardTitle={
                                t("all.errormessage.title") as string
                            }
                            errorMessageCardSubTitle={
                                t("all.errormessage.text") as string
                            }
                            formContext={{
                                t,
                                nameSpace: PageNames.DealerBeforeWeStart,
                                fieldWrapper: {
                                    optionalLabel: "all.optional",
                                },
                            }}
                            initialValues={appData}
                            fields={beforeWeStartFormConfig.fields}
                            onSubmit={handleSubmit}
                            onChange={handleOnChange}
                        >
                            <Navigation
                                t={t}
                                language={language}
                                nextPage={nextPage}
                                nextPageBtnText="dealer.beforewestart.nextstep"
                            />
                        </Form>
                    </div>
                    <Footer />
                </main>
            </DealerLayout>
        </>
    );
};

export default DealerBeforeWeStartPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
